#lock-container-main {
    overflow: hidden;
    position: relative;
    /*height: 500px;*/
    /*width: 500px;*/
    /*border-style: dashed;*/
}

#lock-container-content {
}

#lock-password-input {
    /*position: relative;*/
    /*z-index: 10;*/
    /*top: 20px;*/
    /*left: 20px;*/
}

.lock-overlay {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    padding: 20px;
    text-align: left;
    position: absolute;
    overflow: hidden;
    /*top: -50px;*/
    /*left: 200px;*/
    /*height: 200px;*/
    /*width: 200px;*/
    /*z-index: 5;*/
    /*background-color: red;*/
    transition: all 1s;
    /*transition: width 1s;*/
}